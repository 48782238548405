import React from 'react';

const Home = React.lazy(() => import('./Pages/Home'));
const Login = React.lazy(() => import('./Pages/Login'));
const ResetPassword = React.lazy(() => import('./Pages/ResetPassword'));
const ChangePassword = React.lazy(() => import('./Pages/ChangePassword'));
const AlphatradingHome = React.lazy(() => import('./Pages/AlphatradingHome'));
const ManualSDK = React.lazy(() => import('./Pages/ManualSDK'));
const ManualOrbit = React.lazy(() => import('./Pages/ManualOrbit'));
const WFALP = React.lazy(() => import('./Pages/WFALP'));
const SDKLP = React.lazy(() => import('./Pages/SDKLP'));

const route = [

    { path: '/home', exact: true, name: 'Tabs Authentication', component: Home },
    { path: '/login', exact: true, name: 'Tabs Authentication', component: Login },
    { path: '/reset-senha', exact: true, name: 'Reset Password 1', component: ResetPassword },
    { path: '/nova-senha/:code', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/alphatrading', exact: true, name: 'Tabs Authentication', component: AlphatradingHome },
    { path: '/manual', exact: true, name: 'Tabs Authentication', component: ManualSDK },
    { path: '/manual-orbit', exact: true, name: 'Tabs Authentication', component: ManualOrbit },
    { path: '/wfa-lp', exact: true, name: 'Tabs Authentication', component: WFALP },
    { path: '/sdk-lp', exact: true, name: 'Tabs Authentication', component: SDKLP },

];

export default route;